import React from 'react'
import cover from '../Components/Images/cover1.jpg'
import '../Css/MainPage.css'
import logowhite from '../Components/Images/logo-white.png'
import dental from '../Components/Images/dental.jpg'
import { NavLink } from 'react-router-dom'
import DentalImplants from '../Components/Images/Procedures/DentalImplants.png'
import CosmeticDentistry from '../Components/Images/Procedures/CosmeticDentistry.png'
import RootCanals from '../Components/Images/Procedures/RootCanals.png'
import teethwhitening from '../Components/Images/Procedures/TeethWhitening.png'
import dentalxrays from '../Components/Images/Procedures/DentalXrays.png'
import bracesandimplants from '../Components/Images/Procedures/Bracesimplants.png'
import ammarali from '../Components/Images/Happy Stories/Ammara-ali.png'
import shahrukh from '../Components/Images/Happy Stories/Shahrukh-Akram.png'
import hamza from '../Components/Images/Happy Stories/Hamza.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faGoogle, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Main = () => {
  return (
    <div>
      <div className='coverphotodiv'>
      <img className='coverphoto' src={cover} alt="Cover" />
      </div>
      <div className='bluecover'>
        <div className='bluecoverparent1'>
        <div className='bluecovertext'>
          <h2 style={{color: 'white'}}>Your Oral Health Matters To Us</h2>
          <hr style={{width: '120px', marginLeft: '5px', color: 'white'}} />
          <h1 style={{color: 'white', fontSize: '350%', className: 'bluecovertexth1'}}>Get Brighter Smile From Lahore's Best Dentist</h1>
        </div>
        <div className='whitelogo'>
          <img className='logowhite' src={logowhite} alt="logowhite" />
        </div>
        </div>
      </div>
      <br /><br /><br />
      <div className='bluecoverparent3'>
        <div className='dental'>
            <img className='dentalimg' src={dental} alt="dental" />
        </div>
        <div className='dentaltext'>
          <h1 style={{fontSize: '', marginTop: '100px'}}>Committed To Dental Excellence</h1>
          <hr style={{width: '120px', marginLeft: '5px', color: 'white', marginTop: '30px'}} />
          <p style={{color: 'gray', lineHeight: '25px', marginTop: '30px'}}>Al Mairaj Dental & Cosmetics Clinic offers comprehensive and personalized dental & Cosmetics services in a warm and welcoming environment. With a team of experienced and skilled dentists, the clinic provides a range of treatments, from routine check-ups to advanced procedures, all at affordable prices. Our goal is to ensure that patients recieve the highest quality dental care and leave with a healthy, beautiful smile.</p>
          <br />
          <button className='knowmorebutton'>Know More</button>
        </div>
      </div>
      <br /><br /><br /><br /><br />
      <div className='bluecoverparent2'>
        <div className='consultationmain'>
          <h1 style={{color: 'white'}}>Call Us Today for Consultations</h1>
          <p style={{color: 'white', fontSize: '20px', lineHeight: '25px'}}>Contact our clinic for personalized and professional healthcare services by calling or visiting us during our working hours.</p>
        </div>
        <div className='phonenumber'>
          <NavLink to="tel: +923218403832">
            <button className='phonenumberbutton'>Call +92 321 8403832</button>
          </NavLink>
        </div>
      </div>
      <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '45px', marginTop: '15vw'}}>Our Procedures</h1>
      <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
      <div className='proceduremaintext'>
        <p>At AMDC we aim to provide our patients with best treatment & effective solution, all the facility one state of art the patient treatment includes best consultation & customise treatment plan to suit patient needs. We look forward to intoducing you to different kind of dental and skin perfect experience</p>
      </div>
      <div className='proceduresparent1'>
        <div className='dentalimplantsmain'>
          <img className='dentalimplantsimg' src={DentalImplants} alt="dentalimplants" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Dental Implants</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '80%'}} className='dentalimplantsmaintext'>The dental implant procedure starts with an initial consultation to assess the patient's oral health and plan the treatment. During the implant placement, the dentist surgically inserts the implant into the jawbone and stiches the gum. Over several months, the impant integrates with the bone. Once healed, an abutment is placed, and a custom-made crown is attached to restore the missing tooth. Regular follow-up visits and proper oral hygiene are crucial for the long term success of the implant.</p>
        </div>
        <div className='cosmeticdentistrymain'>
          <img className='cosmeticdentistryimg' src={CosmeticDentistry} alt="cosmeticdentistry" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Cosmetic Dentistry</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '80%'}}className='cosmeticdentistrymaintext'>Cosmetic dentistry procedures focus on improving the appearance of person's smile. Common treatments include teeth whitening, dental veneers, are thin shells placed over the front surface of teeth to improve their colors or shape. Bonding uses tooth colored resin to fix chips or gaps, and tooth reshaping involves removing small amounts of enamel to change a tooth's length or shape. These procedures can enhance a person's smile and confidence.</p>
        </div>
        <div className='rootcanalsmain'>
          <img className='rootcanalsimg' src={RootCanals} alt="rootcanals" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Root Canals</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '80%'}}className='rootcanalsmaintext'>Root Canal treatment is a procedure to repair and save a badly damaged or infected tooth. The dentist removes the damaged area of the tooth (the pulp), cleans and disinfects it, and then fills and seals it. Common causes for root canal treatment include deep decay, repeated dental procedures on the tooth, or a crack or chip in the tooth. Root canals can help alleviate pain and save the natural tooth, preventing the need for extraction and restoring the tooth's function.</p>
        </div>
      </div>
      <div className='proceduresparent1'>
        <div className='teethwhiteningmain'>
          <img className='teethwhiteningimg' src={teethwhitening} alt="teethwhitening" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Teeth Whitening</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '80%'}}className='teethwhiteningmaintext'>Teeth whitening is a cosmetic dental procedure that brightens and lightens the color of teeth. It involves applying a bleaching agent to the teeth, which breaks down stains and discoloration. The procedure can be done in-office by a dentist or at home using custom-made trays and a bleaching gel provided by the dentist. Teeth whitening is a safe and effective way to enhance the appearance of your smile.</p>
        </div>
        <div className='dentalxraysmain'>
          <img className='dentalxraysimg' src={dentalxrays} alt="dentalxrays" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Dental X-Rays</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '80%'}}className='dentalxraysmaintext'>Dental X-rays, also known as radiographs, are images of your teeth that dentists use to evaluate your oral health. During the procedure, you will be asked to bite down on a small piece of plastic while the X-ray machine is positioned next to your cheek. The X-ray image helps the dentist identify issues such as cavities, tooth decay, and problems with the bones that support your teeth. Dental X-rays are a valuble tool in preventive dentistry, allowing dentists to catch and treat dental issues early.</p>
        </div>
        <div className='bracesandimplantsmain'>
          <img className='bracesandimplantsimg' src={bracesandimplants} alt="bracesandimplants" />
          <br />
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white'}}>Braces & Implants</h2>
          <p style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '10px', fontSize: '75%'}}className='bracesandimplantsmaintext'>Braces are orthodontic devices used to straighten and align teeth. The procedure involves bonding brackets to the teeth and connecting them with wires, which are gradually thightened to shift the teeth into the desired position. Braces are commonly used in adolescents, but adults can also benefit from them. Implants, on the other hand, are used to replace missing teeth. The procedure involves surgically placing a titanum implant into the jawbone, which serves as a sturdy anchor for a crown or bridge. Implants are a long-lasting and natural looking solution for missing teeth, providing both cosmetic and functional benefits.</p>
        </div>
      </div>
      <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '45px', marginTop: '15vw'}} id="happy-stories">Happy Stories</h1>
      <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
      <div className='happystoriesparent'>
        <div className='ammaralireview'>
          <div className='ammaraliimgdiv'>
              <img className='ammaraliimg' src={ammarali} alt="ammarali" />
          </div>
          <div className='ammaralitext'>
          <p style={{ color: 'white', textAlign: 'center', fontFamily: 'sans-serif', fontSize: '100%'}}>My experience at Al Mairaj dental and cosmetics clinic was awesome. I used to fear going to dental clinics due to the panademic and also experiencing pain during and afterwards of treatment. But when i came to Al Mairaj after lots of research i flet i was in safe hands. When i arrived at the clinic Dr Shadab and her clinic atmosphere is very friendly, safe and well-kept. Although i was with my kids. Overall mairaj is highly recommended by me</p>
          <h2 style={{fontFamily: 'sans-serif', textAlign: 'center', color: 'white', marginTop: '15%'}}>Ammara Ali</h2>
          <FontAwesomeIcon className='star' icon={faStar}></FontAwesomeIcon>
          <FontAwesomeIcon className='star1' icon={faStar}></FontAwesomeIcon>
          <FontAwesomeIcon className='star1'  icon={faStar}></FontAwesomeIcon>
          <FontAwesomeIcon className='star1'  icon={faStar}></FontAwesomeIcon>
          <FontAwesomeIcon className='star1'  icon={faStar}></FontAwesomeIcon>
          </div>
        </div>
        <div className='hamzaparentdiv'>
          <div className='shahrukhdiv'>
            <div className='shahrukhimgdiv'>
              <img className='shahrukhimg' src={shahrukh} alt="shahrukh" />
            </div>
            <div className='shahrukhreview'>
              <p style={{ color: 'white', fontFamily: 'sans-serif', fontSize: '100%'}} className='shahrukhalamreviewtext'>Professional and highly qualified doctor and staff.. gone through my scaling and hydra facial. Very much satisfied with the results. Highly recommended</p>
              <h2 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '10%'}}>Shahrukh Alam</h2>
              <FontAwesomeIcon className='star2' icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3' icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
            </div>
          </div>
          <div className='hamzadiv'>
            <div className='hamzaimgdiv'>
              <img className='hamzaimg' src={hamza} alt="hamza" />
            </div>
            <div className='hamzareview'>
              <p style={{ color: 'white', fontFamily: 'sans-serif', fontSize: '100%'}} className='hamzareviewtext'>Here at AMDC, I got the most fabulous experience of my dental treatment history. The staff is well behaved and the doctors are very helpful throughout the treatment. I'll highly recommend AMDC. Everyone with dental or skin problems should visit once.</p>
              <h2 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '7%'}}>Hamza Bin Hassan</h2>
              <FontAwesomeIcon className='star2' icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3' icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
              <FontAwesomeIcon className='star3'  icon={faStar}></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
      <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '45px', marginTop: '15vw'}}>Review Us On</h1>
      <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
      <div className='reviewusonparent'>
        <div className='google'>
          <NavLink to="https://www.google.com/maps/place/Al+Mairaj+Dental+%26+Cosmetics+(AMDC)/@31.4736467,74.3789195,17z/data=!3m1!4b1!4m6!3m5!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!8m2!3d31.4736467!4d74.3789195!16s%2Fg%2F11l3p2kgrr?entry=ttu">
            <FontAwesomeIcon className='googleicon' icon={faGoogle}></FontAwesomeIcon>
          </NavLink>
        </div>
        <div className='facebook'>
          <NavLink to="https://www.facebook.com/amdcclinics/">
              <FontAwesomeIcon className='facebookicon' icon={faFacebook}></FontAwesomeIcon>
          </NavLink>
        </div>
        <div className='instagram'>
          <NavLink to="https://www.instagram.com/amdcclinics/">
                <FontAwesomeIcon className='instagramicon' icon={faInstagram}></FontAwesomeIcon>
          </NavLink>
        </div>
      </div>
      <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '45px', marginTop: '20vw'}}>Get a Dazzling Smile in Lowest Price</h1>
      <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
      <p style={{ color: 'black', fontFamily: 'sans-serif', fontSize: '100%', textAlign: 'center', marginTop: '2.5%'}}>Experience high quality dental care at affordable prices at AMDC Dental Clinic.</p>
    </div>
  )
}

export default Main;