import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../Css/Services.css'
import dentalservices from '../Components/Images/Services/dentalservices.jpg'
import aestheticservices from '../Components/Images/Services/aestheticservices.png'

const Procedures = () => {
  return (
    <div>
        <Navbar/>
        <div className='dentalservicesparent'>
            <div className='dentalservicesimgdiv'>
                <img className='dentalservicesimg' src={dentalservices} alt="dentalservices" />
            </div>
            <div className='dentalservices'>
                <h1 style={{color: 'white', textAlign: 'center'}}>Our Dental Services</h1>
                <hr style={{width: '120px', marginLeft: '5px', color: 'white', margin: '0px auto'}} />
                <div className='dentalservicesdiv1'>
                <ul className='dentalservicesul1'>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Scaling Polishing</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Root planning</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Teeth Whitening</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Extractions</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Simple extractions</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Surgical extractions</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Implants</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Fillings</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Pulp capping</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Root canal treatments</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Crown and bridges</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Partial denture</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Crown lengthening</li>
                </ul>
                </div>
            </div>
        </div>
        <div className='aestheticservicesparent'>
            <div className='aestheticservices'>
            <h1 style={{color: 'white', textAlign: 'center'}}>Our Aesthetic Services</h1>
            <hr style={{width: '120px', marginLeft: '5px', color: 'white', margin: '0px auto'}} />
            <div className='aestheticservicesdiv1'>
                <ul className='aestheticservicesul'>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Hydra Facial</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Carbon Peel Facial</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Photo Facial</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Vitamin-C Facial</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Laser Hair Removal</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Laser for Acne</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Laser for Pigmentation</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>PRP Hair</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>PRP Skin</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Bb Glow Treatment</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Meso Hair Treatment</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Meso Glow Treatment</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Lipolytic injections</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Botox</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Fillers</li>
                    <li style={{color: 'white', fontSize: '100%', lineHeight: '180%'}}>Threads</li>
                </ul>
            </div>
            </div>
            <div className='aestheticservicesimgdiv'>
                <img className='aestheticservicesimg' src={aestheticservices} alt="aestheticservices" />
            </div>
        </div>
        <br />
        <Footer/>
    </div>
  )
}

export default Procedures
