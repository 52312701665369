import React, { useState } from 'react';
import '../Css/Navbar.css';
import { NavLink } from 'react-router-dom';
import logo from './Images/AMDC Light Corral Logo .png';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <nav>
        <NavLink to="/"><img className="logo" src={logo} alt="logo" /></NavLink>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </div>
        <ul className={`navbar ${isActive ? 'active' : ''}`}>
          <li><NavLink to="/" onClick={toggleMenu}>Home</NavLink></li>
          <li><NavLink to="/About" onClick={toggleMenu}>About</NavLink></li>
          <li><NavLink to="/Procedures" onClick={toggleMenu}>Services</NavLink></li>
          <li><NavLink to="/" onClick={toggleMenu}>Reviews</NavLink></li>
          <li><NavLink to="/Contact" onClick={toggleMenu}>Contact</NavLink></li>
          <li className="appointment">
            <NavLink to="tel: +923218403832" style={{ color: 'white' }} onClick={toggleMenu}>
              <button style={{ backgroundColor: 'lightcoral', borderColor: 'lightcoral', border: 'none', borderRadius: '10px' }}>
                Get Appointment
              </button>
            </NavLink>
          </li>
        </ul>
      </nav>
      <br />
    </div>
  );
};

export default Navbar;
