import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../Css/Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocation } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  return (
    <div>
      <Navbar/>
      <h1 style={{fontFamily: 'sans-serif', color: 'black', textAlign: 'center', fontSize: '320%'}}>Contact Us</h1>
      <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
      <div className='contactusdiv'>
        <p style={{fontFamily: 'sans-serif'}}><FontAwesomeIcon className='phoneiconcontact' icon={faPhone}></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+92 335 3331138</p>
        <p style={{fontFamily: 'sans-serif', marginTop: '5%'}}><FontAwesomeIcon className='phoneiconcontact' icon={faPhone}></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+92 321 8403832</p>
        <p style={{fontFamily: 'sans-serif', marginTop: '5%'}}><FontAwesomeIcon className='emailiconcontact' icon={faEnvelope}></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;info@amdcclinic.com</p>
        <p style={{fontFamily: 'sans-serif', marginTop: '5%'}}><FontAwesomeIcon className='locationiconcontact' icon={faLocation}></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;331-Z, 1st Floor, Phase-3 DHA, Lahore</p>
      </div>
      <Footer/>
    </div>
  )
}

export default Contact
