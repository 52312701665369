import React from 'react'
import {Navbar, Main, Footer} from '../Components'

const Home = () => {
  return (
    <>
    <Navbar/>
    <Main/>
    <Footer/>
    </>
  )
}

export default Home
