import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import GetAppointment from './Pages/GetAppointment';
import PageNotFound from './Pages/PageNotFound';
import Procedures from './Pages/Procedures';
import Reviews from './Pages/Reviews';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/About" element={<About/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        <Route path="/GetAppointment" element={<GetAppointment/>}/>
        <Route path="/Procedures" element={<Procedures/>}/>
        <Route path="Reviews" element={<Reviews/>}/>
        <Route element={<PageNotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
