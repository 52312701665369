import React from 'react'
import '../Css/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='callus'>
        <h1 style={{marginTop: '25%'}}>Call Us</h1>
        <p>+92 308 4700906</p>
      </div>
      <div className='reachus'>
        <h1 style={{marginTop: '25%'}}>Reach Us</h1>
        <p>331-Z, 1st Floor, Phase-3 DHA, Lahore</p>
      </div>
      <div className='openhours'>
        <h1 style={{marginTop: '25%'}}>Open Hours</h1>
        <p>Mon-Sat 04:00 - 09:00</p>
      </div>
      <div className='followus'>
        <h1 style={{marginTop: '25%'}}>Follow Us</h1>
        <NavLink to="https://www.facebook.com/amdcclinics">
          <FontAwesomeIcon className='facebookiconfooter' icon={faFacebook}></FontAwesomeIcon>
        </NavLink>
        <NavLink to="https://www.instagram.com/amdcclinics/?hl=en">
          <FontAwesomeIcon className='instagramiconfooter' icon={faInstagram}></FontAwesomeIcon>
        </NavLink>
        <NavLink to="https://www.google.com/maps/place//data=!4m3!3m2!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!12e1?source=g.page.m.nr._&laa=nmx-review-solicitation-recommendation-card">
          <FontAwesomeIcon className='googleiconfooter' icon={faGoogle}></FontAwesomeIcon>
        </NavLink>
        <NavLink to="https://www.google.com/maps/place/Al+Mairaj+Dental+%26+Cosmetics+(AMDC)/@31.4992396,74.3644296,17z/data=!3m1!4b1!4m5!3m4!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!8m2!3d31.499235!4d74.3666183?coh=164777&entry=tt&shorturl=1">
          <FontAwesomeIcon className='locationfooter' icon={faLocationArrow}></FontAwesomeIcon>
        </NavLink>
      </div>
    </footer>
  )
}

export default Footer
